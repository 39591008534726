import React, { useEffect, useState } from "react";
import Layout from "Containers/layout";
// import { useDispatch } from "react-redux";
import dynamic from "next/dynamic";

// import ComingSoonContainer from "Containers/coming_soon/coming_soon_container";

const mobile = require("is-mobile");

const MetaHead = dynamic(
	() => import("Containers/meta_head"),
);

const Index = () => {

	const [isMobile, setIsMobile] = useState(true);
	useEffect(() => {

		setIsMobile(mobile());

	}, []);

	const IndexContainer = dynamic(
		() => import("Containers/indexContainer/index_container"),
		{ ssr: false },
	);

	return (
		<>
			{/* <ComingSoonContainer isMobile={isMobile} /> */}

			<Layout
				home
				title="Femaledaily"
				ismobile={isMobile}
				self={" "}
			>
				<MetaHead />

				<main id="main-content">
					<section className="container">

						<IndexContainer isMobile={isMobile} />

					</section>
				</main>
				<style>
					{`
						html{
							scroll-behavior: smooth;
						}
					`}
				</style>
			</Layout>

		</>
	);

};

// Index.propTypes = {
// props: PropTypes.objectOf(PropTypes.any),
// data: PropTypes.objectOf(PropTypes.any),
// };

// Index.defaultProps = {
// props: {},
// data: {},
// };

export default Index;
